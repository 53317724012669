export const PUBLICATION_STATUSES = {
  published: "published",
  unpublished: "unpublished"
}

export const ALLOW_ATTENDEES_TO_EXPORT_MODES = {
  all: "all",
  members: "members"
}

export const TRANSCRIPT_STATUSES = {
  uploaded: "uploaded",
  inProgress: "inProgress",
  complete: "complete",
  failed: "failed"
}
