export const BREAKPOINTS = {
  xSmall: "576px",
  small: "768px",
  medium: "992px",
  semiLarge: "1100px",
  large: "1200px",
  xLarge: "1440px",
  xxLarge: "1875px"
}

export const SPACES = {
  xxSmall: "0.25rem",
  xSmall: "0.5rem",
  small: "1rem",
  medium: "1.5rem",
  semiLarge: "2rem",
  large: "3rem",
  xLarge: "5rem"
}

export const FONT_WEIGHTS = {
  normal: "300",
  semiBold: "400",
  bold: "500",
  xBold: "700"
}

export const FONT_SIZES = {
  xxSmall: "0.5rem",
  xSmall: "0.75rem",
  small: "0.85rem",
  medium: "1rem",
  semiLarge: "1.25rem",
  large: "1.5rem",
  xLarge: "2rem"
}

export const messageBlockPadding = "1.2rem"
export const messageActorAvatar = "19px"
export const cardBorderRadius = "5px"
export const contentsSidebarToggleBorderRadius = "4px"
export const headerMobileSize = "49px"
export const headerSize = "49px"
export const sidebarToggleButtonSize = "64px"
