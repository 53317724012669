import { camelizeKeys } from "humps"

const defaultOptions = {
  credentials: "include"
}

export const jsonFetch = (url, options) => {
  return fetch(url, { ...defaultOptions, options })
    .then((response) => {
      if (response.ok) {
        return response.json()
      }

      const error = new Error(`${response.status} ${response.statusText}`)
      error.response = response

      throw error
    })
    .then(camelizeKeys)
}

export const getMeetingPack = ({ meetingPackId }) =>
  jsonFetch(`/api/meeting_packs/${meetingPackId}`)

export const getMeetingPackAgendaItems = ({ meetingPackId }) =>
  jsonFetch(`/api/meeting_packs/${meetingPackId}/agenda_items`)

export const getMeetingPackDocuments = ({ meetingPackId }) =>
  getMeetingPackAgendaItems({ meetingPackId }).then((agendaItems) =>
    agendaItems.flatMap(({ documents }) => documents)
  )

export const getDocument = ({ documentId }) =>
  fetch(`/documents/${documentId}/download`, defaultOptions).then((response) =>
    response.arrayBuffer()
  )

export const getAnnotations = ({ documentId }) =>
  jsonFetch(`/api/documents/${documentId}/annotations`)

export const encryptionKeys = ({ documentId }) => {
  return jsonFetch(`/api/documents/${documentId}/encryption_keys`)
}

export default {
  jsonFetch,
  getMeetingPack,
  getMeetingPackAgendaItems,
  getMeetingPackDocuments,
  getDocument,
  getAnnotations,
  encryptionKeys
}
