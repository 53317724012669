import React from "react"
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"

import { csrfToken } from "src/helpers/ajax"

const httpLink = new HttpLink({ uri: "/graphql", credentials: "include" })

const authMiddleware = new ApolloLink((operation, forward) => {
  if (typeof window !== "undefined") {
    operation.setContext({
      headers: {
        "X-CSRF-TOKEN": csrfToken()
      }
    })
  }

  return forward(operation)
})

const errorLink = onError(({ _graphQLErrors, networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    window.location.reload()
  }
})

const link = ApolloLink.from([errorLink, authMiddleware, httpLink])

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    possibleTypes: {
      Thread: ["ChatThread", "DiscussionThread", "MeetingPackThread"],
      Suggestion: [
        "MeetingSuggestion",
        "SchemeSuggestion",
        "GroupSuggestion",
        "DiscussionSuggestion",
        "AppSuggestion"
      ]
    },
    typePolicies: {
      SearchResultItem: {
        keyFields: ["id", "type"]
      }
    }
  }),
  credentials: "include",
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network"
    }
  }
})

const withApolloProvider = (Component) => (props) => {
  return (
    <ApolloProvider client={client}>
      <Component {...props} />
    </ApolloProvider>
  )
}

export { client, withApolloProvider }
