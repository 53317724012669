import { canStoreMeetingPack } from "src/features/offline/helpers/meetingPacks"
import { currentPageIndexKey } from "src/features/meetingPacks/MeetingPack/helpers"
import { summarizeViewed } from "src/constants/storage"
import { addRoutes, getSavedDocumentsStats } from "src/features/offline/helpers/cache"
import { CACHE_NAMES } from "src/features/offline/constants"

import defaultApiClient from "./apiClient"
import CacheStore from "./CacheStore"

export default class MeetingPackStore {
  static ROUTES_TO_CACHE = [
    "/meeting_packs/:meeting_pack_id",
    "/meeting_packs/:meeting_pack_id/view",
    "/meeting_packs/:meeting_pack_id/attendees"
  ]

  constructor({ apiClient, cacheStore = new CacheStore(), useCache = false } = {}) {
    this.apiClient = apiClient || defaultApiClient
    this.useCache = useCache
    this.cacheStore = cacheStore
  }

  getDocumentWithAnnotations = ({ documentId, userId }) => {
    const documentPromise = this.getDocument({ documentId })
    const annotationsPromise = this.getAnnotations({ documentId, userId })

    return Promise.all([documentPromise, annotationsPromise]).then(
      ([document, annotations]) => ({
        document,
        annotations
      })
    )
  }

  getDocument = ({ documentId }) => {
    return this.cacheStore
      .getDocument({ documentId })
      .then((document) => {
        if (!document) {
          throw new Error("Document not found")
        }

        return document
      })
      .catch(() => {
        return this.apiClient.getDocument({ documentId }).then((document) => {
          if (this.useCache) {
            this.cacheStore.saveDocument({ documentId, document })
          }

          return document
        })
      })
  }

  getAnnotations = ({ documentId, userId }) => {
    // We can not rely on catch of API request because service worker completes it successfully
    if (!navigator.onLine) return this.cacheStore.getAnnotations({ documentId, userId })

    return this.apiClient
      .getAnnotations({ documentId })
      .then((annotations) => {
        if (this.useCache) {
          this.cacheStore.saveAnnotations({
            documentId,
            annotations,
            userId
          })
        }

        return annotations
      })
      .catch(() => {
        return this.cacheStore.getAnnotations({ documentId, userId })
      })
  }

  getAllCachedMeetingPacks = () => {
    return this.cacheStore.getAllMeetingPacks()
  }

  clearOutdatedMeetingPacks = () => {
    return this.getAllCachedMeetingPacks().then((meetingPacks) => {
      return Promise.all(
        meetingPacks.map(({ id: meetingPackId }) => {
          return this.apiClient.getMeetingPack({ meetingPackId }).then((meetingPack) => {
            if (!canStoreMeetingPack(meetingPack)) {
              this.clearMeetingPackAndAssociationsCache({ meetingPackId })
            }
          })
        })
      )
    })
  }

  clearMeetingPackAndAssociationsCache = ({ meetingPackId }) => {
    return this.apiClient
      .getMeetingPackDocuments({
        meetingPackId
      })
      .then((documents) => {
        documents.forEach(async ({ id: documentId }) => {
          await this.cacheStore.clearDocument({ documentId })
          await this.cacheStore.clearAnnotations({ documentId })
          localStorage.removeItem(
            summarizeViewed("MeetingPacks::AgendaItemDocument", documentId)
          )
          localStorage.removeItem(currentPageIndexKey(documentId))
        })

        return this.cacheStore.clearMeetingPack({ meetingPackId })
      })
  }

  cacheMeetingPackRoutes = ({ meetingPackId }) => {
    return addRoutes({
      cacheName: CACHE_NAMES.ROUTES,
      routes: MeetingPackStore.ROUTES_TO_CACHE.map((route) =>
        route.replace(":meeting_pack_id", meetingPackId)
      )
    })
  }

  cacheMeetingPack = async ({ meetingPackId }) => {
    return this.apiClient.getMeetingPack({ meetingPackId }).then(async (meetingPack) => {
      const documents = await this.apiClient.getMeetingPackDocuments({
        meetingPackId
      })

      await this.cacheStore.saveMeetingPack({
        meetingPack: { ...meetingPack, documents }
      })

      return meetingPack
    })
  }

  cacheDocuments = async ({ meetingPackId, userId }) => {
    const documents = await this.apiClient.getMeetingPackDocuments({
      meetingPackId
    })

    const { saved } = await getSavedDocumentsStats(meetingPackId)

    return Promise.all(
      documents.flatMap(({ id: documentId }) => {
        return [
          saved.includes(documentId)
            ? Promise.resolve()
            : this.cacheDocument({ documentId }),
          this.cacheAnnotations({ documentId, userId })
        ]
      })
    )
  }

  cacheDocument = ({ documentId }) => {
    return this.apiClient.getDocument({ documentId }).then((document) => {
      return this.cacheStore.saveDocument({ documentId, document })
    })
  }

  cacheAnnotations = ({ documentId, userId }) => {
    return this.apiClient.getAnnotations({ documentId }).then((annotations) =>
      this.cacheStore.saveAnnotations({
        documentId,
        annotations,
        userId
      })
    )
  }
}
