import { createCipheriv, createDecipheriv } from "crypto"

const algorithm = "aes256"

export const encryptBlob = ({ key, iv, data }) => {
  const cipher = createCipheriv(algorithm, key, iv)
  return Buffer.concat([cipher.update(Buffer.from(data)), cipher.final()])
}

export const decryptBlob = ({ key, iv, data }) => {
  const decipher = createDecipheriv(algorithm, key, iv)
  return Buffer.concat([decipher.update(Buffer.from(data)), decipher.final()])
}

export const encryptString = ({ key, iv, data }) => {
  const cipher = createCipheriv(algorithm, key, iv)

  const encrypted = cipher.update(data, "utf8", "hex")
  return encrypted + cipher.final("hex")
}
