import { PUBLICATION_STATUSES } from "src/resources/meetingPacks/constants"
import { beById } from "src/helpers/document"
import SyncDataStore from "src/features/offline/helpers/SyncDataStore"
import CacheStore from "src/features/offline/helpers/CacheStore"

import {
  postSyncCachedMeetingPackMessage,
  postClearMeetingPackCacheMessage,
  postClearOutdatedMeetingPacksCacheMessage,
  postSyncCachedPagesMessage
} from "./messages"

export const canStoreMeetingPack = ({ publicationStatus, startDate }) => {
  const currentDate = new Date()
  const beginningOfYesterday = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 1
  )

  const isAfterYesterday = new Date(startDate) >= beginningOfYesterday
  const isPublished = publicationStatus === PUBLICATION_STATUSES.published

  return isAfterYesterday && isPublished
}

export const getSavedMeetingPacksIds = () => {
  const cacheStore = new CacheStore()

  return cacheStore.getAllMeetingPacksIds()
}

export const isMeetingSavedOffline = ({ meetingPackId }) => {
  return getSavedMeetingPacksIds().then((ids) => ids.includes(Number(meetingPackId)))
}

export const saveOffline = ({ meetingPackId, userId }) => {
  postSyncCachedMeetingPackMessage({ meetingPackId, userId })
  postSyncCachedPagesMessage()
}

export const clearOffline = ({ meetingPackId }) => {
  postClearMeetingPackCacheMessage({ meetingPackId })
  postSyncCachedPagesMessage()
}

export const syncAllSavedMeetingPacks = ({ userId }) => {
  return getSavedMeetingPacksIds().then((ids) => {
    const syncDataStore = new SyncDataStore()

    ids.forEach((meetingPackId, i) => {
      syncDataStore
        .wasMeetingSyncedRecently({
          meetingPackId
        })
        .then((wasMeetingSyncedRecently) => {
          if (!wasMeetingSyncedRecently) {
            setTimeout(() => {
              postSyncCachedMeetingPackMessage({
                meetingPackId,
                userId
              })
            }, i * 2000)
          }
        })
    })
  })
}

export const clearOutdatedMeetingPacks = () => {
  const syncDataStore = new SyncDataStore()

  syncDataStore
    .wasOutdatedCacheClearedRecently()
    .then((wasOutdatedCacheClearedRecently) => {
      if (!wasOutdatedCacheClearedRecently) {
        postClearOutdatedMeetingPacksCacheMessage()
      }
    })
}

export const showSavedOfflineIconInSidebar = ({ meetingPackId }) => {
  const menuLink = beById("meeting-pack-menu-link", meetingPackId)

  if (menuLink) {
    menuLink.find(".menu-link").addClass("saved-offline")
    menuLink.find(".saved-offline-icon").removeClass("d-none")
  }
}

export const hideSavedOfflineIconInSidebar = ({ meetingPackId }) => {
  const menuLink = beById("meeting-pack-menu-link", meetingPackId)

  if (menuLink) {
    menuLink.find(".menu-link").removeClass("saved-offline")
    menuLink.find(".saved-offline-icon").addClass("d-none")
  }
}
