import IdbStore from "src/features/offline/helpers/IdbStore"
import { STORE_NAMES } from "src/features/offline/constants"
import KeysStore from "src/features/offline/helpers/KeysStore"
import { decryptBlob, encryptBlob } from "src/features/offline/helpers/encryption"

export default class CacheStore {
  constructor() {
    this.meetingPacksStore = new IdbStore({ storeName: STORE_NAMES.MEETING_PACKS })
    this.annotationsStore = new IdbStore({ storeName: STORE_NAMES.ANNOTATIONS })
    this.documentsStore = new IdbStore({ storeName: STORE_NAMES.DOCUMENTS })
    this.groupAppStore = new IdbStore({ storeName: STORE_NAMES.GROUP_APPS })
    this.pspdfKitUpdateStore = new IdbStore({ storeName: STORE_NAMES.PSPDFKIT_UPDATES })
    this.customPromptAIAnswersStore = new IdbStore({
      storeName: STORE_NAMES.CUSTOM_PROMPT_AI_ANSWER
    })
    this.keysStore = new KeysStore()
  }

  getCustomPromptAIAnswer = (entity, id) => {
    return this.customPromptAIAnswersStore.get(`${entity}-${id}`)
  }

  saveCustomPromptAIAnswer = (entity, id, prompt, answer) => {
    return this.customPromptAIAnswersStore.set(`${entity}-${id}`, {
      prompt,
      answer
    })
  }

  getMeetingPack = ({ meetingPackId }) => {
    return this.meetingPacksStore.get(meetingPackId)
  }

  getDocument = ({ documentId }) => {
    return Promise.all([
      this.keysStore.getEncryptionKeys(documentId),
      this.documentsStore.get(documentId)
    ]).then(([keys, document]) => {
      // previously saved unencrypted document
      if (document instanceof ArrayBuffer || !document) {
        return document
      }

      return decryptBlob({
        key: keys.encryptionKey,
        iv: keys.initializationVector,
        data: document
      }).buffer
    })
  }

  getAnnotations = ({ documentId, userId }) => {
    return this.annotationsStore.get(this.userAnnotationsKey({ documentId, userId }))
  }

  getAllMeetingPacks = () => {
    return this.meetingPacksStore.getAllValues()
  }

  getAllMeetingPacksIds = () => {
    return this.meetingPacksStore.getAllKeys()
  }

  getAllDocumentsIds = () => {
    return this.documentsStore.getAllKeys()
  }

  saveMeetingPack = ({ meetingPack }) => {
    return this.meetingPacksStore.set(meetingPack.id, meetingPack)
  }

  saveDocument = ({ documentId, document }) => {
    return this.keysStore
      .getEncryptionKeys(documentId)
      .then(({ encryptionKey, initializationVector }) => {
        const encryptedDocument = encryptBlob({
          key: encryptionKey,
          iv: initializationVector,
          data: document
        })

        return this.documentsStore.set(documentId, encryptedDocument)
      })
  }

  saveAnnotations = ({ documentId, userId, annotations }) => {
    return this.annotationsStore.set(
      this.userAnnotationsKey({ documentId, userId }),
      annotations
    )
  }

  clearMeetingPack = ({ meetingPackId }) => {
    return this.meetingPacksStore.delete(meetingPackId)
  }

  clearDocument = ({ documentId }) => {
    return Promise.all([
      this.documentsStore.delete(documentId),
      this.keysStore.clearEncryptionKeysFromStore(documentId)
    ])
  }

  clearAnnotations = ({ documentId }) => {
    const annotationsKey = this.userAnnotationsKey({ documentId, userId: "" })

    return this.annotationsStore
      .getAllKeys()
      .then((allUserDocumentKeys) =>
        allUserDocumentKeys.filter((key) => key.startsWith(annotationsKey))
      )
      .then((documentKeys) => {
        return Promise.all(
          documentKeys.map((documentKey) => {
            return this.annotationsStore.delete(documentKey)
          })
        )
      })
  }

  userAnnotationsKey = ({ documentId, userId }) => {
    return `${documentId}:${userId}`
  }

  getRecord = ({ recordId }) => {
    return this.groupAppStore.get(recordId)
  }

  saveRecord = ({ recordId, record }) => {
    return this.groupAppStore.set(recordId, record)
  }

  clearRecord = ({ recordId }) => {
    return this.groupAppStore.delete(recordId)
  }

  savePSPDFKitUpdate = ({ version, reloadUuid }) => {
    return this.pspdfKitUpdateStore.set(version, reloadUuid || "true")
  }

  getPSPDFKitUpdate = async ({ version, reloadUuid }) => {
    const result = await this.pspdfKitUpdateStore.get(version)
    return result === (reloadUuid || "true")
  }
}
