export const findActiveItemIdByDocument = ({ agendaItems, documentId }) => {
  return agendaItems.reduce((itemId, agendaItem) => {
    if (itemId) {
      return itemId
    }

    if (agendaItem.documents.find(({ id }) => id === documentId)) {
      return agendaItem.id
    }

    return null
  }, null)
}

export const findActiveDocumentIdByItem = ({ agendaItems, itemId }) => {
  const agendaItem = agendaItems.find(({ id }) => id === itemId)

  return agendaItem?.documents?.[0]?.id
}

export const getAllItems = ({ agendaItems }) => {
  return agendaItems
    .map((agendaItem) => (agendaItem.break ? [] : [agendaItem, ...agendaItem.documents]))
    .flat()
}

export const findAgendaItem = (agendaItems, itemId) =>
  agendaItems.find(({ id }) => id === itemId)

export const findAgendaItemMemberships = (agendaItems, itemId) =>
  findAgendaItem(agendaItems, itemId)?.memberships || []

export const findFirstAttendingAgendaItem = (agendaItems) =>
  agendaItems.find((agendaItem) => agendaItem.attending && !agendaItem.break)

export const findFirstAttendingAgendaItemId = (agendaItems) =>
  findFirstAttendingAgendaItem(agendaItems)?.id || null

export const findPreviousAttendingAgendaItem = ({ agendaItems, activeItemId }) => {
  const activeItemIndex = agendaItems.findIndex((item) => item.id === activeItemId)
  return agendaItems
    .slice(0, activeItemIndex)
    .reverse()
    .find((agendaItem) => agendaItem.attending && !agendaItem.break)
}

export const findNextAttendingAgendaItem = ({ agendaItems, activeItemId }) => {
  const activeItemIndex = agendaItems.findIndex((item) => item.id === activeItemId)

  return agendaItems.find(
    (agendaItem, i) => i > activeItemIndex && agendaItem.attending && !agendaItem.break
  )
}

export const findNextNavigationResource = ({
  agendaItems,
  activeItemId,
  activeDocumentId
}) => {
  const activeItem = findAgendaItem(agendaItems, activeItemId)
  const nextItem = findNextAttendingAgendaItem({
    agendaItems,
    activeItemId
  })

  if (activeDocumentId) {
    const activeDocumentIndex = activeItem.documents.findIndex(
      (document) => document.id === activeDocumentId
    )
    const nextDocument = activeItem.documents[activeDocumentIndex + 1]

    if (nextDocument) {
      return { document: nextDocument }
    }
    return { agendaItem: nextItem }
  }

  if (activeItem) {
    const nextDocument = activeItem?.documents?.[0]

    if (nextDocument) {
      return { document: nextDocument }
    }
    if (nextItem) {
      return { agendaItem: nextItem }
    }
  }

  return {}
}

export const findPreviousNavigationResource = ({
  agendaItems,
  activeItemId,
  activeDocumentId
}) => {
  const activeItem = findAgendaItem(agendaItems, activeItemId)
  const previousItem = findPreviousAttendingAgendaItem({
    agendaItems,
    activeItemId
  })

  if (activeDocumentId) {
    const activeDocumentIndex = activeItem.documents.findIndex(
      (document) => document.id === activeDocumentId
    )
    const previousDocument = activeItem.documents[activeDocumentIndex - 1]

    if (previousDocument) {
      return { document: previousDocument }
    }
    return { agendaItem: activeItem }
  }

  if (activeItem) {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const previousDocument = previousItem?.documents[previousItem?.documents?.length - 1]

    if (previousDocument) {
      return { document: previousDocument }
    }
    if (previousItem) {
      return { agendaItem: previousItem }
    }
  }

  return {}
}

export const saveMeetingPackVisit = ({ meetingPackId, itemId, documentId }) => {
  localStorage.setItem(`meeting-${meetingPackId}`, JSON.stringify({ itemId, documentId }))
}

export const getMeetingPackVisit = ({ meetingPackId }) => {
  try {
    return JSON.parse(localStorage.getItem(`meeting-${meetingPackId}`)) || {}
  } catch {
    return {}
  }
}

export const currentPageIndexKey = (documentId) =>
  `agenda-item-document-current-page-index-${documentId}`
