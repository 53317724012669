import { gql } from "@apollo/client/core"

import IdbStore from "src/features/offline/helpers/IdbStore"
import { STORE_NAMES } from "src/features/offline/constants"

export default class GraphQLCacheStore {
  static requestCacheKey = ({ query, variables = {} }) => {
    const parsedQuery = typeof query === "string" ? gql(query) : query
    const queryKey = parsedQuery.definitions.map(GraphQLCacheStore.operationKey)

    return JSON.stringify(queryKey) + JSON.stringify(variables)
  }

  static operationKey = (definition) => {
    if (!definition?.selectionSet?.selections) {
      return definition?.name?.value
    }

    return {
      [definition?.name?.value]: definition.selectionSet.selections
        .map((selection) => GraphQLCacheStore.operationKey(selection))
        .filter((selection) => selection !== "__typename")
    }
  }

  constructor() {
    this.store = new IdbStore({ storeName: STORE_NAMES.GRAPHQL_REQUESTS })
  }

  getCache = ({ query, variables }) => {
    return this.store.get(this.cacheKey({ query, variables }))
  }

  getCacheAsResponse = ({ query, variables }) => {
    return this.getCache({ query, variables }).then((responseData) =>
      this.deserializeResponse(responseData)
    )
  }

  setCache = ({ query, variables }, data) => {
    return this.store.set(this.cacheKey({ query, variables }), data)
  }

  cacheKey = ({ query, variables }) => {
    return GraphQLCacheStore.requestCacheKey({ query, variables })
  }

  deserializeResponse = (responseData) => {
    return new Response(JSON.stringify(responseData), {
      headers: { "Content-Type": "application/json" }
    })
  }
}
