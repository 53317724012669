import { BREAKPOINTS } from "src/styles/sizes"

export const isSafari = () => /safari/i.test(navigator.userAgent)
export const isMobileSafari = () =>
  !/Chrome/i.test(navigator.userAgent) &&
  !/CriOS\//i.test(navigator.userAgent) && // mobile chrome
  isSafari()

export const isIOS = () =>
  /ipad|iphone|ipod/i.test(navigator.userAgent) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes("Mac") && "ontouchend" in document)

export const isIpad = () =>
  /ipad/i.test(navigator.userAgent) ||
  (/Macintosh; Intel Mac OS/i.test(navigator.userAgent) &&
    window.orientation !== undefined)

export const isIphone = () => /iphone/i.test(navigator.userAgent)

export const isTouchDevice = () =>
  typeof document.documentElement.ontouchstart !== "undefined"

export const isMobile = () =>
  typeof window.orientation !== "undefined" ||
  navigator.userAgent.indexOf("IEMobile") !== -1

export const isPortableDevice = () => {
  const { userAgent } = navigator

  if (/windows phone/i.test(userAgent)) {
    return true
  }

  if (/android/i.test(userAgent)) {
    return true
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !/11_(3|4)/.test(userAgent)) {
    return true
  }

  if (window.orientation !== undefined) {
    return true
  }

  return false
}

export const isPWA = () => window.matchMedia("(display-mode: standalone)").matches

export const isWideScreen = () => window.innerWidth > parseInt(BREAKPOINTS.semiLarge, 10)
