export const skipAssetHash = ({ pathname, search = "" }) => {
  const paths = pathname.split("/")
  const lastPath = paths.pop()

  let pathnameWithoutHash = ""
  if (lastPath.includes("-")) {
    const lastPathParts = lastPath.split("-")
    const hashAndExtensions = lastPathParts.pop()
    const [, ...extensions] = hashAndExtensions.split(".")
    const lastPathWithoutHash = [lastPathParts.join("-"), extensions.join(".")].join(".")
    pathnameWithoutHash = [...paths, lastPathWithoutHash].join("/")
  } else {
    pathnameWithoutHash = pathname
  }

  return `${pathnameWithoutHash}${search}`
}

export const skipUrlParamsPlugin = {
  cacheKeyWillBeUsed: ({ request }) => {
    const url = new URL(request.url)

    return url.pathname
  }
}

export const skipAssetHashPlugin = {
  cacheKeyWillBeUsed: ({ request }) => {
    const url = new URL(request.url)
    const { pathname, search } = url

    return skipAssetHash({ pathname, search })
  }
}
