import meetingPacksTagsQuery from "src/features/Home/Meetings/meetingPacksTags.gql"
import sidebarDataQuery from "src/features/Sidebar/sidebarData.gql"

export const SERVICE_WORKER_MESSAGE_TYPES = {
  SYNC_ALL_CACHED_MEETING_PACKS: "SYNC_ALL_CACHED_MEETING_PACKS",
  SYNC_CACHED_MEETING_PACK: "SYNC_CACHED_MEETING_PACK",
  SYNC_CACHED_PAGES: "SYNC_CACHED_PAGES",
  CLEAR_MEETING_PACK_CACHE: "CLEAR_MEETING_PACK_CACHE",
  CLEAR_OUTDATED_MEETING_PACKS_CACHE: "CLEAR_OUTDATED_MEETING_PACKS_CACHE",
  PREFETCH_ASSETS: "PREFETCH_ASSETS"
}

export const STORE_NAMES = {
  DOCUMENTS: "DOCUMENTS",
  ANNOTATIONS: "ANNOTATIONS",
  OFFLINE_ANNOTATIONS: "OFFLINE_ANNOTATIONS",
  MEETING_PACKS: "MEETING_PACKS",
  GRAPHQL_REQUESTS: "GRAPHQL_REQUESTS",
  SYNC_DATA: "SYNC_DATA",
  KEYS: "QUERIES",
  GROUP_APPS: "GROUP_APPS",
  PSPDFKIT_UPDATES: "PSPDFKIT_UPDATES",
  CUSTOM_PROMPT_AI_ANSWER: "CUSTOM_PROMPT_AI_ANSWER"
}

export const CRUD = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE"
}

export const CACHE_NAMES = {
  ROUTES: "ROUTES",
  API_REQUESTS: "API_REQUESTS",
  ASSETS: "ASSETS",
  UPLOADS: "UPLOADS"
}

export const PRECACHED_ROUTES = [
  {
    url: "/meetings",
    apiRequests: [
      "/api/meeting_packs?page[offset]=0&page[limit]=20",
      "/api/tags",
      "/api/accounts"
    ]
  }
]

export const PRECACHED_GRAPHQL_QUERIES = [
  {
    query: meetingPacksTagsQuery,
    variables: {}
  },
  {
    query: sidebarDataQuery,
    variables: {}
  }
]

export const EVENTS = {
  SAVED_OFFLINE: "meeting-packs:saved-offline",
  REMOVED_OFFLINE: "meeting-packs:remove-offline"
}
