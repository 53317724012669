import { openDB } from "idb"

export default class IdbStore {
  constructor({ storeName, storeVersion = 1 }) {
    this.storeName = storeName
    this.storeVersion = storeVersion
  }

  openDb = () => {
    const { storeName } = this

    return openDB(storeName, this.storeVersion, {
      upgrade(db) {
        db.createObjectStore(storeName)
      },
      // eslint-disable-next-line no-console
      blocked: () => console.warn("blocked"),
      // eslint-disable-next-line no-console
      blocking: () => console.warn("blocking"),
      // eslint-disable-next-line no-console
      terminated: () => console.warn("terminated")
    })
  }

  transaction = () => {
    return this.openDb().then((db) => {
      return db.transaction(this.storeName, "readwrite")
    })
  }

  objectStore = () => {
    return this.transaction().then((transaction) => {
      return {
        transaction,
        objectStore: transaction.objectStore(this.storeName)
      }
    })
  }

  get = (name) => {
    return this.objectStore().then(({ objectStore }) => {
      return objectStore.get(name)
    })
  }

  getAllKeys = () => {
    return this.objectStore().then(({ objectStore }) => {
      return objectStore.getAllKeys()
    })
  }

  getAllValues = () => {
    return this.objectStore().then(({ objectStore }) => {
      return objectStore.getAll()
    })
  }

  set = (name, data) => {
    return this.objectStore().then(({ objectStore, transaction }) => {
      objectStore.put(data, name)

      return transaction.complete
    })
  }

  delete = (name) => {
    return this.objectStore().then(({ objectStore, transaction }) => {
      objectStore.delete(name)

      return transaction.complete
    })
  }

  clear = () => {
    return this.objectStore().then(({ objectStore, transaction }) => {
      objectStore.clear()

      return transaction.complete
    })
  }
}
