import IdbStore from "src/features/offline/helpers/IdbStore"
import { STORE_NAMES } from "src/features/offline/constants"

export default class SyncDataStore {
  static SYNC_THRESHOLD = 10 * 60 * 1000

  static CLEARING_OUTDATED_CACHE_DATE_KEY = "CLEARING_OUTDATED_CACHE_DATE"

  static CLEARING_OUTDATED_CACHE_THRESHOLD = 24 * 60 * 60 * 1000

  constructor() {
    this.dataStore = new IdbStore({ storeName: STORE_NAMES.SYNC_DATA })
  }

  wasMeetingSyncedRecently({ meetingPackId }) {
    return this.getMeetingSyncDate({ meetingPackId }).then((date) => {
      if (!date) {
        return false
      }

      return date.getTime() > new Date().getTime() - SyncDataStore.SYNC_THRESHOLD
    })
  }

  wasOutdatedCacheClearedRecently() {
    return this.dataStore
      .get(SyncDataStore.CLEARING_OUTDATED_CACHE_DATE_KEY)
      .then((date) => {
        if (!date) {
          return false
        }
        return (
          date.getTime() >
          new Date().getTime() - SyncDataStore.CLEARING_OUTDATED_CACHE_THRESHOLD
        )
      })
  }

  getClearingOutdatedCacheDate() {
    return this.dataStore.get(SyncDataStore.CLEARING_OUTDATED_CACHE_DATE_KEY)
  }

  saveClearingOutdatedCacheDate({ date } = {}) {
    return this.dataStore.set(
      SyncDataStore.CLEARING_OUTDATED_CACHE_DATE_KEY,
      date || new Date()
    )
  }

  getMeetingSyncDate({ meetingPackId }) {
    return this.dataStore.get(meetingPackId)
  }

  saveMeetingSyncDate({ meetingPackId, date }) {
    return this.dataStore.set(meetingPackId, date || new Date())
  }

  clearMeetingSyncDate({ meetingPackId }) {
    return this.dataStore.delete(meetingPackId)
  }
}
