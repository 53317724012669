import GraphQLCacheStore from "src/features/offline/helpers/GraphQLCacheStore"
import CacheStore from "src/features/offline/helpers/CacheStore"
import { client } from "src/app/apollo"
import {
  CACHE_NAMES,
  PRECACHED_GRAPHQL_QUERIES,
  PRECACHED_ROUTES
} from "src/features/offline/constants"
import { skipAssetHash } from "src/features/offline/helpers/workboxPlugins"

export const addRoutes = ({ cacheName, routes }) => {
  return caches.open(cacheName).then((cache) => cache.addAll(routes))
}

export const cacheAssets = (routes) => {
  caches.open(CACHE_NAMES.ASSETS).then((cache) => {
    routes.forEach((route) => {
      fetch(route).then((response) =>
        cache.put(skipAssetHash({ pathname: route, search: "" }), response)
      )
    })
  })
}

export const cacheRoutes = () => {
  addRoutes({
    cacheName: CACHE_NAMES.ROUTES,
    routes: PRECACHED_ROUTES.map(({ url }) => url)
  })

  addRoutes({
    cacheName: CACHE_NAMES.API_REQUESTS,
    routes: PRECACHED_ROUTES.flatMap(({ apiRequests }) => apiRequests).filter(Boolean)
  })
}

export const getSavedDocumentsStats = async (meetingPackId) => {
  const cacheStore = new CacheStore()

  const [meetingPack, allDocumentsIds] = await Promise.all([
    cacheStore.getMeetingPack({ meetingPackId }),
    cacheStore.getAllDocumentsIds()
  ])

  if (!meetingPack) {
    return null
  }

  const all = (meetingPack.documents || []).map(({ id }) => id)
  const saved = all.filter((documentId) => allDocumentsIds.includes(documentId))
  const unsaved = all.filter((documentId) => !saved.includes(documentId))

  return {
    all,
    saved,
    unsaved
  }
}

export const cacheGraphQLRequest = ({ query, variables = {} }) => {
  const graphqlCacheStore = new GraphQLCacheStore()

  client.query({ query, variables, fetchPolicy: "network-only" }).then(({ data }) => {
    graphqlCacheStore.setCache({ query, variables }, data)
  })
}

export const cacheGraphQLRequests = () => {
  PRECACHED_GRAPHQL_QUERIES.forEach((query) => {
    cacheGraphQLRequest(query)
  })
}

export const graphqlCacheHandlerCallback = async ({ event }) => {
  const graphqlCacheStore = new GraphQLCacheStore()
  const requestBody = await event.request.clone().json()

  return fetch(event.request.clone())
    .then((response) => {
      graphqlCacheStore.getCache(requestBody).then((cachedData) => {
        // if response have already been precached - update cache
        if (cachedData) {
          response
            .clone()
            .json()
            .then((responseData) =>
              graphqlCacheStore.setCache(requestBody, responseData.data)
            )
        }
      })

      return response.clone()
    })
    .catch(async (error) => {
      const cachedData = await graphqlCacheStore.getCache(requestBody)

      if (cachedData) {
        return new Response(JSON.stringify({ data: cachedData }))
      }

      throw error
    })
}
